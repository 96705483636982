﻿import {get, $, $$, getDataJson, postDataText, getDataText, show} from "@/Utils"
import { initGoogleMap } from "@/StoreMap"
import {ReplaceProduct} from "@/interfaces"
import {DataAttributes, TradeInStep} from "@/enums"
import { initCarousels } from "@/sharedComponents/Carousel/Carousel"

const calcContainerId = "calcContainer";
let products: ReplaceProduct[] = [];
let product: ReplaceProduct;

function updateCalcHtml(html: string) {
    const calcContainer = get(calcContainerId);
    if (calcContainer) {
        get("calcContainer").innerHTML = html;
    } else {
        console.error(`Expected element with ID ${calcContainerId} is missing!`);
    }
    init();
}

function initSearch() {
    const processResponse = (responseProducts: ReplaceProduct[]) => {
        products = responseProducts;
        const formatProduct = (p: ReplaceProduct) =>
            `<li>
            <button type='button' class='autocomplete-list__result'
                data-productid="${p.id}">
                ${p.productName}
            </button/>
            </li>`;

        const autocompleteList = $(".autocomplete-list");
        autocompleteList.innerHTML = products
            .map(formatProduct)
            .reduce((acc, curr) => acc + curr, "");
    }

    const queryProducts = (searchTerm: string) => {
        const url = `${window.config.tradeIn.getProductsUrl}?searchTerm=${searchTerm}`;
        getDataJson(url, processResponse);
    }

    const clearResults = () => $(".autocomplete-list").innerHTML = "";

    const autocompleteClickListener = (e: Event) => {
        const target = e.target as HTMLElement;
        const pid = parseInt(target.getAttribute("data-productid"));
        if (isNaN(pid)) return;

        product = products.find(x => x.id === pid);
        postDataText(window.config.tradeIn.evaluationUrl, product, updateCalcHtml);
    }

    let timeout: number = null;
    const inputListener = (e: Event) => {
        window.clearTimeout(timeout);
        const input = e.currentTarget as HTMLInputElement;
        const searchTerm = input.value;

        if (searchTerm.length < 3) {
            return clearResults();
        }

        timeout = window.setTimeout(() => queryProducts(searchTerm), 750);
    }

    get("searchProducts").addDataBoundEventListener("input", inputListener);
    $(".autocomplete-list").addDataBoundEventListener("click", autocompleteClickListener);
}

function initEvaluation() {
    const handler = (e: Event) => {
        const target = e.target as HTMLElement;
        const radio = target.previousElementSibling as HTMLInputElement;
        radio.checked = true;

        const container = target.closest("[data-question-container]");
        const nextContainer = container.nextElementSibling as HTMLElement;
        if (nextContainer) {
            nextContainer.classList.remove("disabled-question");
        } else {
            const nextButton = get("next") as HTMLElement;
            show(nextButton);
        }
    }

    $$(".replace-answers span").forEach(x => x.addDataBoundEventListener("click", handler));
}

function initNav(step: TradeInStep) {
    if (step === TradeInStep.Search) return;

    const back = get("back") as HTMLElement;
    const next = get("next") as HTMLElement;
    let backClick: Function = null;

    if (step === TradeInStep.Evaluation) {

        backClick = () => getDataText(window.config.tradeIn.searchUrl, updateCalcHtml);
        const nextClick = () => {
            const answers: number[] = [];
            const answerTexts: string[] = [];
            const questions: string[] = [];
            const questionLabels: string[] = [];

            $$("[data-question-container]").forEach(x => {
                const input = x.querySelector(".replace-answer:checked") as HTMLInputElement;
                answers.push(parseInt(input.value));

                const answerText = input.nextElementSibling as HTMLElement;
                answerTexts.push(answerText.textContent);

                const question = x.querySelector(".replace-question") as HTMLElement;
                questions.push(question.textContent);
                questionLabels.push(question.getAttribute("data-label"));
            });

            product.answers = answers;
            product.answerTexts = answerTexts;
            product.questions = questions;
            product.questionLabels = questionLabels;

            postDataText(window.config.tradeIn.summaryUrl, product, updateCalcHtml);
        }

        next.addDataBoundEventListener("click", nextClick);
    }
    if (step === TradeInStep.Summary) {
        backClick = () => postDataText(window.config.tradeIn.evaluationUrl, product, updateCalcHtml);
    }

    back.addDataBoundEventListener("click", backClick);
}

function init() {
    const step = parseInt($(".calc-content").getAttribute(DataAttributes.Step)) as TradeInStep;

    switch (step) {
        case TradeInStep.Search:
            initSearch();
            break;
        case TradeInStep.Evaluation:
            initEvaluation();
            break;
        case TradeInStep.Summary:
            break;
    }

    initNav(step)
}

function initTradeIn() {

    initGoogleMap();

    const mapContainerId = "mapContainer"
    const map = get(mapContainerId);
    if (map) {
        if (!map.querySelector(".map-content")) {
            map.innerHTML = "";
            map.append($(".map-content"));
        }
    } else {
        console.error(`Expected element with ID ${mapContainerId} is missing!`);
    }

    const calc = get(calcContainerId);
    if (calc) {
        if (!calc.querySelector(".calc-content")) {
            calc.innerHTML = "";
            calc.append($(".calc-content"));
        }
    } else {
        console.error(`Expected element with ID ${calcContainerId} is missing!`);
    }

    init();
}

window.addEventListener("load", () => {
    initTradeIn();
    initCarousels();
});
